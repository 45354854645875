import { graphql } from "gatsby"
import React, { useContext, useEffect } from "react"
import { scroller } from "react-scroll"
import styled from "styled-components"

// Context
import AppContext from "../context/AppContext"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Components
import BottomSection from "../components/BottomSection"
import Seo from "../components/Seo"
import Testimonials from "../components/Testimonials"
import ContentWrapper from "../components/ContentWrapper"
import AboutSection from "../components/AboutSection"
import TeamComponent from "../components/TeamComponent"

// Constants
import { getUrl } from "../constants/paths"
import vector from "../images/vector.svg"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Types
import { IAboutPage } from "../prismic-types/about"

interface IProps {
  content: IAboutPage
}

const AboutPage: React.FC<IProps> = ({ content }) => {
  const { getLocalizedLink } = useLocale()
  const [marketingSection] = content.marketingSections

  const context = useContext(AppContext)
  const seo = {
    title: content.meta.title,
    description: content.meta.description,
    lang: content.meta.lang,
    imageUrl: "",
    datePublished: content.meta.publishedDate,
    dateModified: content.meta.modifiedDate,
    slug: "about",
  }

  useEffect(() => {
    if (context && context.scrollToAnchor !== null) {
      scroller.scrollTo(context.scrollToAnchor, {
        duration: 800,
        spy: true,
        smooth: true,
      })
      context.setScrollToAnchor(null)
    }
  }, [])

  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        dateModified={seo.dateModified}
        datePublished={seo.datePublished}
        slug={seo.slug}
        lang={seo.lang}
        breadCrumbItems={[{ name: "About Us", url: "about" }]}
      />
      {content.header && (
        <AboutPageIntro>
          <StyledContainer>
            <InnerWrapper>
              <Title>{content.header.title}</Title>
            </InnerWrapper>
            <StyledStorySection>
              <StyledSubTitle>{content.header.subTitle}</StyledSubTitle>
            </StyledStorySection>
          </StyledContainer>
        </AboutPageIntro>
      )}
      <AboutSection data={content.content} />
      <TeamComponent
        team={content.team}
        title="Meet the team"
        background={colors.main["700"]}
      />
      <Testimonials {...content.testimonials} />
      <BottomSection
        title={marketingSection.title || ""}
        text={marketingSection.text || ""}
        sectionId="our-story"
        titleFontSize="48px"
        titleColor={colors.white}
        textColor={colors.white}
        buttonLabel={marketingSection.button?.label}
        buttonLink={getLocalizedLink(getUrl(marketingSection.button?.link))}
        background={{
          type: "imageWithGradient",
          imageUrl: marketingSection.image?.imageUrl || "",
          gradientStartColor: colors.white,
          gradientEndColor: colors.purpleLightDim,
          gradientStartPercentage: 0,
          gradientEndPercentage: 0,
        }}
        buttonVariant="tertiary"
      />
    </>
  )
}

const AboutPageIntro = styled.div`
  position: relative;
`

const StyledContainer = styled(ContentWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 64px;
`
const Title = styled.h1`
  ${textStyles.titleXL};
  font-size: 32px !important;
  text-align: center;
  color: ${colors.main["200"]};

  ${mq.from.S`
  font-size: 40px !important;
`}

  ${mq.from.M`
    font-size: 64px !important;
    line-height: 76px !important;
  `}
`

const InnerWrapper = styled.div`
  background-color: ${colors.main["700"]};
  border-radius: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 96px 20px;
  margin-top: 80px;

  ${mq.from.S`
  margin-top: 90px;
  padding: 104px 44px 96px;
`}

  ${mq.from.M`
  margin-top: 166px;
  padding: 120px 44px;
`}
`

const StyledStorySection = styled.div`
  position: relative;
  width: 150px;
  height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 40%;
    width: 100%;
    height: 100%;
    background-image: url("${vector}");
    background-repeat: no-repeat;
    z-index: 0;
  }
`

const StyledSubTitle = styled.h5`
  color: ${colors.purpleLight};
  ${textStyles.titleS};
  text-align: center;
  font-weight: 500 !important;
  z-index: 1;

  ${mq.from.M`
    font-size: 22px !important;
    line-height: 30px;
  `}
`
export const query = graphql`
  query aboutPage($locale: String!) {
    raw: prismicAboutUsPage(lang: { eq: $locale }) {
      id
      lang
      last_publication_date
      first_publication_date
      data {
        meta_description
        meta_title
        heading {
          html
          text
        }
        content {
          image {
            alt
            url
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          left_text {
            html
            text
          }
          right_text {
            html
            text
          }
          left_text_color
          right_text_color
          allow_full_width
        }
        marketing_section {
          button_label
          button_link
          image {
            url
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          text {
            html
            text
          }
          title {
            html
            text
          }
        }
        sub_title {
          html
          raw
          text
        }
      }
    }
    teamMembers: prismicManifestoPageBodyTeamMember {
      items {
        image {
          url
        }
        member_name {
          text
        }
        member_role {
          text
        }
      }
    }
    homepage: prismicHomePage(lang: { eq: $locale }) {
      data {
        # Testimonials
        testimonials_title {
          html
        }
        testimonials_description {
          html
        }
        testimonials {
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          cover_image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          client_name
          client_position
          testimonial {
            html
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const { raw, teamMembers, homepage } = data

  const meta = {
    title: raw.data.meta_title,
    description: raw.data.meta_description,
    publishedDate: raw.first_publication_date,
    modifiedDate: raw.last_publication_date,
    lang: raw.lang,
  }

  const header = {
    title: raw.data.heading.text,
    subTitle: raw.data.sub_title.text,
  }

  const team = teamMembers.items.map((teamMember: any) => ({
    image: teamMember.image,
    memberName: teamMember.member_name[0].text,
    memberRole: teamMember.member_role[0].text,
  }))

  const testimonials = {
    title: homepage.data.testimonials_title?.html,
    testimonials: homepage.data.testimonials.map((testimonial: any) => ({
      image: {
        alt: testimonial.image?.alt,
        url: testimonial.image?.fluid,
      },
      coverImage: {
        alt: testimonial.cover_image?.alt,
        url: testimonial.cover_image?.fluid,
      },
      client: {
        name: testimonial.client_name,
        position: testimonial.client_position,
      },
      quote: testimonial.testimonial?.html,
    })),
  }

  const marketingSections = raw.data.marketing_section.map((section: any) => ({
    title: section.title.text,
    text: section.text.html,
    button: {
      link: section.button_link,
      label: section.button_label,
    },
    image: {
      alt: section.image?.alt,
      url: section.image?.fluid,
      imageUrl: section.image?.url,
    },
  }))

  const content = {
    meta,
    header,
    content: raw.data.content,
    team,
    testimonials,
    marketingSections,
  }

  return <AboutPage content={content} />
}
